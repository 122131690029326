<template>
    <el-dialog
        v-dialog-drag
        :title="title"
        :width="width"
        :visible="visible"
        :close-on-click-modal="false"
        @close="handleClose"
    >
        <el-form ref="editForm" :model="data" :rules="rules">
            <el-form-item label="标题：" prop="Title">
                <el-input v-model="data.Title" placeholder="请输入标题" />
            </el-form-item>
            <el-form-item label="排序：">
                <el-input-number v-model="data.SortIndex" placeholder="请输入排序" style="width:100%;" controls-position="right"/>
            </el-form-item>
            <el-form-item label="内容：">
                <ueditor-wrap
                    v-model="data.Content"
                    :config="{
                        initialFrameWidth: 1000,
                        initialFrameHeight: 580,
                    }"
                ></ueditor-wrap>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleClose">关闭</el-button>
            <el-button type="primary" size="small" :loading="loading" @click="handleSave">保存</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { submit } from '@/api/base';

export default {
    name: 'DocumentEdit',
    data() {
        return {
            loading: false,
            visible: false,
            width: '1080px',
            title: '新增',
            isNew: true,
            data: {},
            rules: {
                Title: [
                    { required: true, message: '请输入标题', trigger: 'blur' }
                ]
            },
        }
    },
    methods: {
        initDialog(title, id) {
            const _this = this;

            _this.visible = true;

            if (title) {
                _this.title = title;
            }

            if (!id){
                _this.isNew = true;
            }else{
                _this.isNew = false;
                _this.$nextTick(async () => {
                    const { data } = await submit(`/api/document/get?id=${id}`);
                    _this.data = data;
                });
            }
        },
        handleSave() {
            const _this = this;
            _this.$refs.editForm.validate(async(valid) => {
                if (!valid) return false;

                const param = JSON.parse(JSON.stringify(_this.data))

                let result;
                _this.loading = true;
                if(_this.isNew){
                    result = await submit('/api/document/create', param).finally(()=>{
                        _this.loading = false;
                    });
                }else{
                    result = await submit('/api/document/update', param).finally(()=>{
                        _this.loading = false;
                    });
                }

                if(result.success){
                    _this.handleClose();
                    _this.$message({
                        type: 'success',
                        message: '保存成功'
                    });
                    _this.$emit('handleSearch');
                }else{
                    _this.$message({
                        type: 'error',
                        message: result.msg
                    });
                }
            });
        },
        handleClose() {
            this.visible = false;
            this.data = {};
            this.$refs.editForm.resetFields();
        }
    }
}
</script>